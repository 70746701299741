import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import TicketSvgPath from '../images/ticket.svg';
import { useSiteMetaContext } from '../context/SiteMetaContext';
import Layout from '../layout/Layout';
import Seo from '../components/Seo';
import ScrollTarget from '../components/ScrollTarget';
import TooltipSvgInner from '../components/TooltipSvgInner';
import LandingIntroSection from '../sections/landing/landingIntro';
import HomepageExpertsSection from '../sections/homepageExperts';
import LandingAdditionalContentSection from '../sections/landing/landingAdditionalContent';
import LandingGiftsMoreDetailsSection from '../sections/landing/landingGiftsMoreDetails';
import YourHostSection from '../sections/yourHost';
import WhatIsSuperConferenceSection from '../sections/whatIsSuperConference';
import ConferenceContentSection from '../sections/conferenceContent';
import RegistrationSection from '../sections/registration';
import StickyRegistration from '../sections/stickyRegistration';
import SponsorsSection from '../sections/sponsors';
import FeaturedGiftSection from '../sections/featuredGift';
import SpeakersCarouselSection from '../sections/speakersCarousel';

const RegistrationMobileOnly = styled(RegistrationSection)`
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    display: none;
  `};
`;

const PaperBackground = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
`;

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.white};
`;

const IndexPage = ({ location }) => {
  const { title } = useSiteMetaContext();
  const { allAirtable, landingBg, landingBgMobile } = useStaticQuery(graphql`
    query {
      landingBg: file(relativePath: { eq: "landing_bg_meditation.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      landingBgMobile: file(
        relativePath: { eq: "landing_bg_meditation_mobile.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allAirtable(
        filter: {
          table: { eq: "Session" }
          data: {
            BonusSession: { ne: true }
            Speaker: {
              elemMatch: {
                data: { MainHost: { ne: true }, Host: { ne: true } }
              }
            }
          }
        }
      ) {
        edges {
          node {
            data {
              Name
              DateOrder
              BonusSession
              Content {
                childMarkdownRemark {
                  html
                }
              }
              Speaker {
                id
                data {
                  Name
                  WebsiteUri
                  ExpertOrder
                  Host
                  MainHost
                  Avatar {
                    localFiles {
                      childImageSharp {
                        gatsbyImageData(width: 400)
                      }
                    }
                  }
                }
              }
            }
            id
          }
        }
      }
    }
  `);
  // Affiliate Id is returned from infusionsoft's redirect. We store it here so we can post it when creating firebase registration later.
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (params.affiliate) {
    localStorage.setItem('superconferenceAffiliate', params.affiliate);
  }

  const speakers =
    allAirtable?.edges
      ?.map((edge) => edge?.node)
      .filter(({ data }) => !!data.Speaker)
      .sort(
        (speakerA, speakerB) =>
          speakerA.data.ExpertOrder - speakerB.data.ExpertOrder
      )
      .map(({ data }) => ({
        id: data.Speaker[0].id,
        name: data.Speaker[0].data.Name,
        tagline: data.Name,
        image: data.Speaker[0].data.Avatar.localFiles[0],
        slug: data.Slug,
        content: data.Content,
        category: data.Category,
      })) || [];

  return (
    <Layout>
      <Seo title="Home" />

      <LandingIntroSection
        title="Fatigue: 12 Secrets to Recovery"
        subtitle={
          <div>
            Download your <strong>FREE E-Book</strong> and uncover twelve
            powerful fatigue-recovery stories
          </div>
        }
        buttonLabel="Download E-Book Now"
        landingBg={landingBg}
        landingBgMobile={landingBgMobile}
        hideVideo
        hideNav
      />

      <RegistrationMobileOnly primaryButtonLabel="Download E-Book Now" />

      <FeaturedGiftSection />

      <ScrollTarget id="signup" />
      <StickyRegistration
        title={<span>Download The E-Book Now</span>}
        primaryButtonLabel="Download Now"
        tooltipInner={
          <TooltipSvgInner
            svgPath={TicketSvgPath}
            content={
              <>
                Also Includes
                <br />
                Free Ticket
              </>
            }
          />
        }
      />

      <Background>
        <SpeakersCarouselSection
          speakers={speakers}
          svgPath={TicketSvgPath}
          pretitle={
            <div>
              Your "Fatigue: 12 Secrets to Recovery" E-Book{' '}
              <u>also includes:</u>
            </div>
          }
          title={
            <div>
              FREE TICKET To The Upcoming
              <br />
              {title}
            </div>
          }
          subtitle={
            <div>
              The conference features <span>40+ Speakers</span>, including:
            </div>
          }
        />
      </Background>

      <ConferenceContentSection />

      <WhatIsSuperConferenceSection />

      <PaperBackground>
        <HomepageExpertsSection />
      </PaperBackground>

      <LandingAdditionalContentSection />

      <LandingGiftsMoreDetailsSection />

      <YourHostSection showHeader />

      <SponsorsSection />
    </Layout>
  );
};

export default IndexPage;
